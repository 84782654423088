<template>

    <CEESAR-ControlBox  :label="label"
                        :required="required"
                        :horizontal="horizontal">

        <input class="CEESAR-InputBoxField" type="text"
            v-on:focus="OnFocused" v-on:blur="OnUnfocused"
            v-bind:value="value" v-on:input="$emit('input', $event.target.value)"/>
            
    </CEESAR-ControlBox>

</template>

<script>
export default {
    name: "CEESAR-TextInputBox",
    props:{
        label: "", 
        value: "",
        horizontal: false,
        required: false
    },
    data(){
        return{
            isFocused: false
        };
    },
    computed:{
        IsFocused(){
            return this.isFocused || ( this.value != undefined && this.value != "");
        }
    },
    methods:{
        OnFocused(){
            this.isFocused=true;
        },
        OnUnfocused(){
            this.isFocused=false;
        }
    }
}
</script>

<style>

</style>